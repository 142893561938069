import {reactive,computed,ref} from "vue"
import $api from "@/api"
import { validLength,validPhone,validEmail,validUrl } from "@/utils/validator"
export default function useCertCompany(ProcessTabs){
    const showSuccess=ref(false)
    const certForm=ref(null)
    const form=reactive({
        companyName: '',
        images:'',
         cate:'',
         companyAddress: '',
         companySize:'',
         companyProfile:'',
         companyProducts:'',
         contacts:'',
         phones:'',
         telPhones:'',
         email:'',
         url:'',
         companyLogo:"",
         address:""
   })
   const rules=reactive({
    contacts:[{ required: true, trigger: 'blur',name:"联系人",validator:validLength }],
    phones:[{ required: true, trigger: 'blur',name:"手机",validator:validPhone }],
    telPhones:[{ required: true, trigger: 'blur',name:"联系电话",validator:validPhone }],
    email:[{ required: true, trigger: 'blur',name:"邮箱",validator:validEmail }],
    url:[{ required: true, trigger: 'blur',name:"公司官网",validator:validUrl }],

   })
   const disableNext=computed(()=>{
    let flag=false
    const [{label}]=ProcessTabs.tabs.filter(e=>e.active)
    switch (label) {
        case '公司名称':
            if(!form.companyName){
                flag=true
            }
            break;
    case '营业执照':
            if(!form.images){
                flag=true
            }
            break;
            case '公司简介':
            if(["companyAddress","cate","companySize","companyProfile","companyProducts","companyLogo","address"].some(e=>!form[e])){
                flag=true
            }
            break;
            case '联系方式':
            if(["contacts","phones","telPhones","email"].some(e=>!form[e])){
                flag=true
            }
            break;
        default:
            break;
    }
    return flag
    })
   return {
       certForm,
    showSuccess,
       form,
       disableNext,
       rules,
   }
}