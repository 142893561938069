<template>
  <div class="container">
    <el-upload
      ref="circlePic"
      class="avatar-uploader"
      action="https://v1.hitzone.cn/api/article/UploadFile"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />

      <img
        :src="require('@/assets/images/default_logo.png')"
        class="logo el-icon-plus avatar-uploader-icon"
        v-else
      />
    </el-upload>
    <div>
      <p class="fs-12">点击图片上传公司LOGO,图像格式为jpg\png，大小不超过3m</p>
    </div>
  </div>
</template>
<script setup>
import { ref,defineEmits } from "vue";
import Dialog from "@/utils/ui/dialog"
const circlePic=ref("")
const imageUrl = ref("");
const emits=defineEmits(["remoteUrl"])
const handleAvatarSuccess = (res, file) => {
    emits('remoteUrl',res)
};
const beforeAvatarUpload = (file) => {
  if(file.size/1024/1024>3){
            circlePic.value.clearFiles()
            Dialog.message("error","文件大小不可超过3M")
            return false
        }
  img2Base64(file);
};
const img2Base64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    imageUrl.value = reader.result;
  };
};

</script>
<style lang="less" scoped>
.container{
    text-align: center;
    line-height: 0;
}
.avatar,
.logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.fs-12{
    font-size: 12px !important;
}
</style>
