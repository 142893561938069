<template>
  <div class="inner_banner">
    <a class="db" href="javascript:void(0)"><img :src="require('@/assets/images/rzsq.png')"/></a>
  </div>

  <div class="rzsq" id="certi">
    <ul class="sequence rel auto">
      <li v-for="item in ProcessTabs.tabs" :class="{ active: item.active }">
        <div class="li_content">
          <i class="iconfont icon-enterprise"></i>
          <span>{{ item.label }}</span>
        </div>
      </li>
    </ul>

    <!-- enterprise-content -->
    <div class="enterprise-content">
      <el-form :model="form" :rules="rules" ref="certForm">
        <!-- 公司名字 -->
        <div class="ent_box" v-show="ProcessTabs.activeIndex === 0">
          <div class="auto img_box_1 tc">
            <img :src="require('@/assets/images/ent.png')" />
          </div>
          <p class="tc">
            认证前，请准备好公司的营业执照扫描件、公司logo（jpg/png）
          </p>
          <el-form-item class="name_box auto">
            <el-input v-model="form.companyName" placeholder="公司名称"></el-input>
          </el-form-item>
          <!-- <button @click="click_1()">下一步</button> -->
        </div>
        <!-- 营业执照 -->
        <div class="ent_box" v-show="ProcessTabs.activeIndex === 1">
          <div class="yy_box auto">
            <p class="yy">营业执照扫描件(jpg/png，大小不超过5M)</p>
            <div class="upload_box w100p">
              <div class="btn_box">
                <el-upload
                  :limit="1"
                  ref="uploadRef"
                  class="avatar-uploader"
                  :class="{ disable: hideUpload }"
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                  :on-change="fileChange"
                >
                  <template #default>
                    <div
                      class="img_box_1 auto mt5 border-none default-pic"
                      :class="{ notshow: hideUpload }"
                    >
                      <img :src="require('@/assets/images/yy.png')" class="w100p cert-pic" />
                    </div>
                  </template>
                  <template #file="{file}">
                    <div class="operate-container">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePicPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </template>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <!-- 公司简介 -->
        <div class="ent_box" v-show="ProcessTabs.activeIndex === 2">
          <el-form-item class=" auto company-logo" >
              <circle-pic-upload @remoteUrl="getRemoteUrl"></circle-pic-upload>
          </el-form-item>
          <el-form-item class="select_box">
            <el-select v-model="form.cate" placeholder="请选择公司类型" multiple collapse-tags>
             <el-option v-for="item in companyType" :key="item.id" :label="item.label" :value="item.label"></el-option>
            </el-select>

            

            <el-select v-model="form.companySize" placeholder="公司规模">
              <el-option label="50人以下" value="50人以下"></el-option>
              <el-option label="50-100人" value="50-100人"></el-option>
              <el-option label="100-500人" value="100-500人"></el-option>
              <el-option label="500-1000人" value="500-1000人"></el-option>
              <el-option label="1000人以上" value=">000人以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="desc_box auto company-address">
            <el-select v-model="form.companyAddress" placeholder="所属省份">
              <el-option v-for="item in provinces" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-input
            style="margin-left: 60px;flex-grow: 1;"
              type="text"
              v-model="form.address"
              placeholder="公司详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item class="desc_box auto ">
            <t-editor  class="editor" types="companyProfile" placeholder="请填写公司简介" :value="form.companyProfile"  @inputContent="inputRichText"/>
          </el-form-item>

          <el-form-item class="solution_box auto ">
            <t-editor  class="editor" types="companyProducts" placeholder="请填写公司产品解决方案" :value="form.companyProducts"  @inputContent="inputRichText"/>
          </el-form-item>
        </div>
        <!-- 联系方式 -->
        <div class="ent_box" v-show="ProcessTabs.activeIndex === 3">
          
          <div class="contact-info">
            <el-form-item class="contact_box auto" prop="contacts">
              <el-input v-model="form.contacts" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item class="contact_box auto" prop="phones">
              <el-input v-model="form.phones" placeholder="手机"></el-input>
          </el-form-item>
          <el-form-item class="contact_box auto" prop="telPhones">
              <el-input v-model="form.telPhones" placeholder="联系电话"></el-input> 
          </el-form-item>
          <el-form-item class="contact_box auto" prop="email">
              <el-input v-model="form.email" placeholder="联系邮箱"></el-input>
          </el-form-item>
          </div>
          <el-form-item class="web_box auto" prop="url">
              <el-input v-model="form.url" placeholder="网址"></el-input>
          </el-form-item>
        </div>

        <!-- end enterprise-content -->
      </el-form>

      <div class="button_box auto">
        <el-button
          type="primary"
          autofocus
          class="prev"
          v-if="ProcessTabs.activeIndex !== 0"
          @click="prevStep()"
          >上一步</el-button
        >
        <el-button
          type="primary"
          autofocus
          class="next"
          :disabled="disableNext"
          v-if="ProcessTabs.activeIndex !== ProcessTabs.tabs.length - 1"
          @click="nextStep()"
          >下一步</el-button
        >
        <el-button
          type="primary"
          autofocus
          class="submit"
          v-if="ProcessTabs.activeIndex == ProcessTabs.tabs.length - 1"
          @click="onSubmit()"
          >提交</el-button
        >
      </div>
    </div>

    <!-- 提交成功弹窗 -->
    <div class="success_box rel" v-show="showSuccess">
      <i class="icon-false iconfont abs" @click="showSuccess=false"></i>
      <img :src="require('@/assets/images/yxb-white.png')" class="succ_logo db" />
      <div class="info_box auto">
        <h2>提交成功！</h2>
        <img :src="require('@/assets/images/success.png')" class="db auto" />
        <p class="p1">
          您已成功提交
          <span>{{ form.name }}</span>
          的入驻材料，平台将在3个工作日内审核完成，若未认证成功，请重新认证。
        </p>
        <p>如有其他问题，请联系 公众号："医信邦" 咨询。</p>
      </div>
    </div>
  </div>
  <el-dialog v-model="preview.show">
    <img width="100%" :src="preview.url" class="preview-img" alt="" />
  </el-dialog>
  <div class="mask" v-show="showSuccess"></div>
</template>
<script setup>
import { computed, reactive, watchEffect } from "vue";
import {useStore} from "vuex"
import {useRouter} from "vue-router"
import $api from "@/api"
import Dialog from "@/utils/ui/dialog"
import uploadImg from "@/utils/file/uploadImg"
import useCertCompany from "@/composable/company/useCertCompany";
import useUploadFile from "@/composable/universal/useUploadFile";
import useInfoTree from "@/composable/universal/useInfoTree"
import useSearchForm from "@/composable/bidding/useSearchForm"
import TEditor from "@/components/TEditor"
import CirclePicUpload from "@/components/circlePicUpload"

const store=useStore()
const router=useRouter()
const ProcessTabs = reactive({
      tabs: [
        { label: "公司名称", active: true },
        { label: "营业执照", active: false },
        { label: "公司简介", active: false },
        { label: "联系方式", active: false },
      ],
      activeIndex: 0,
    });
    const userInfo=computed(()=>store.state.userState.userInfo || {})
    const {showSuccess,certForm, form, rules, disableNext } = useCertCompany(ProcessTabs);
    const {classifies}=useInfoTree("15")
    const companyType=computed(()=>classifies.value.filter(e=>e.value!=="-1"))
    const {provinces}=useSearchForm()
    const preview = reactive({
      show: false,
      url: "",
    });
    const {
      imgs,
      uploadRef,
      fileChange,
      handlePicPreview,
      handleRemove,
      hideUpload,
    } = useUploadFile(preview);
    
    watchEffect(() => {
      form.images = imgs.value.length ? true : "";
    });
    // 公司logo
    const getRemoteUrl=(logoUrl)=>{
      form.companyLogo=logoUrl
    }
    
    //#region  上一步 & 下一步
    const prevStep = () => {
      ProcessTabs.tabs.find((e, i, arr) => {
        if (e.active && !!i) {
          e.active = false;
          arr[i - 1].active = true;
          ProcessTabs.activeIndex = i - 1;
          return -1;
        }
      });
    };
    const nextStep =async() => {
      if(!!userInfo.value.vendorsId){
          Dialog.alert("提示","您已拥有厂商信息,请勿重复注册",true,false,()=>{
            router.push({name:"PersonHome"})
          })
          return;
        }
      if (ProcessTabs.activeIndex === 1) {
      const logUrl = await  uploadImg([...imgs.value]);
      form.images = logUrl;
      }
      if(!ProcessTabs.activeIndex){
       const [err,data] =await $api.company.validCompanyName({VendorsName:form.companyName})
       if(!data.succeeded){
         Dialog.alert("提示",data.errors,true,true)
         return false
       }
      }
      ProcessTabs.tabs.find((e, i, arr) => {
        if (e.active && i !== arr.length - 1) {
          e.active = false;
          arr[i + 1].active = true;
          ProcessTabs.activeIndex = i + 1;
          return -1;
        }
      });
    };
    //#endregion
   
    //#region  提交
    const onSubmit=()=>{
      let result=form.cate.reduce((prev,next)=>{
        let cates=classifies.value.find(e=>e.label===next)
        if(cates) prev.push(cates?.value);
        return prev
      },[])
      certForm.value.validate(async(valid)=>{
        if(!valid)return;
        form["vendorsId"]=result
        form["id"]=0
        const [err,data]=await $api.company.companyIntake(form)
        if(!data.succeeded){
          Dialog.alert("抱歉",data.errors || "提交异常,请重试o(〒﹏〒)o",true,true)
        }else{
          showSuccess.value=true
          setTimeout(() => {
            showSuccess.value=false
            router.push({
              name:"Home"
            })
          }, 3000);
        }
      })
    }
    //#endregion

    //#region 富文本填写
    const inputRichText=(val,types)=>form[types]=val;
</script>
<style scoped></style>
<style lang="less" scoped>
.operate-container {
  height: 100%;
}
.el-upload-list__item-thumbnail {
  object-fit: cover;
}
.upload_box {
  display: flex;
  justify-content: center !important;
  height: 220px;
}
:deep(.disable .el-upload--picture-card){
  display: none;
}
.pic-item {
  width: 178px;
  height: 178px;
}
.preview-img {
  height: 100%;
  width: 100%;
}
.border-none {
  border: none;
}
.avatar-uploader {
  text-align: center;
  // margin-top: 30px;
}
:deep(.el-upload ){
  border: none;
}
:deep(.disable .el-upload--picture-card ){
  display: none;
}
:deep(.el-upload--picture-card) {
  width: 100%;
}
:deep(.el-upload-list__item) {
  width: 225px;
  height: 154px;
  object-fit: cover;
}
.operate-container {
  width: 100%;
  height: 100%;
}
.border-none {
  border: none;
}
.notshow {
  display: none;
}
.cert-pic {
  width: 225px;
  height: 154px;
  margin-top: 0px !important;
}
.renzheng {
  bottom: 30px;
}
.article-list {
  padding: 0 20px 0 30px;
}
.article-item {
  padding: 35px 0 30px;
}
.pagnation {
  text-align: center;
}
.article-info {
  text-align: left;
}
.submit-btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}
.submit-btn > button {
  background-color: #1188f7;
  color: white;
}
.preview-img {
  height: 100%;
  width: 100%;
}
.info_box {
  position: relative;
}
.default-pic {
  width: auto !important;
  height: 0px !important;
  margin-top: 0px !important;
  background: none !important;
}
.contact-info{
  margin-top: 80px !important;
  width: 550px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content:center;
  justify-content: space-between;
}
.contact_box{
  width: 260px;
  margin: 0 0 22px 0 !important;
}
.mask{
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  background: rgba(.1, .1, .1, .1);
}
.company-logo{
  margin-top: 20px;
}
:deep(.company-address .el-form-item__content){
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
